// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_ballContainer__3xp10 {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,eAAe;IACf,eAAe;AACnB","sourcesContent":[".ballContainer {\n    margin-top: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    flex-wrap: wrap;\n    padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ballContainer": `home_ballContainer__3xp10`
};
export default ___CSS_LOADER_EXPORT___;

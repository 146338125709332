import React from "react";
import styles from './index.module.css';

export default function Ball() {
    return (
        <div className={styles.container}>
            <div className={styles.ball}>
                <div>LOGO</div>
            </div>
            <div className={'pt-2'}>TEXTO DE EJEMPLO</div>
        </div>
    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ball_container__gZC9P {
    width: 30%;
    color: #5d8eea;
    font-weight: 600;
    margin-bottom: 20px;
}

.ball_ball__6dSJ7 {
    box-shadow: #282c34 0 3px 10px 1px;
    border-radius: 50%;
    border: 6px solid #FFF;
    background-color: #208120;
    color: #FFFFFF;
    aspect-ratio: 1;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/components/ball/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 30%;\n    color: #5d8eea;\n    font-weight: 600;\n    margin-bottom: 20px;\n}\n\n.ball {\n    box-shadow: #282c34 0 3px 10px 1px;\n    border-radius: 50%;\n    border: 6px solid #FFF;\n    background-color: #208120;\n    color: #FFFFFF;\n    aspect-ratio: 1;\n    min-width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ball_container__gZC9P`,
	"ball": `ball_ball__6dSJ7`
};
export default ___CSS_LOADER_EXPORT___;

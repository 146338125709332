// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_container__\\+1k8d {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #267b26;
    color: #FFFFFF;
    font-size: 24px;
    box-shadow: #282c34 0 1px 4px 1px;
}`, "",{"version":3,"sources":["webpack://./src/compontents/header/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #267b26;\n    color: #FFFFFF;\n    font-size: 24px;\n    box-shadow: #282c34 0 1px 4px 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `header_container__+1k8d`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

export default function Banner() {
    return (
        <div className={'my-2 py-3'} style={{
            display: 'flex', justifyContent: "center", alignItems: "center",
            width: '100%', backgroundColor: '#6286da', color: '#FFF'
        }}>
            <div>Contactamos en segundos a bancos y financieras</div>
        </div>
    )
}
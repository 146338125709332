import React from 'react';
import Header from "../../compontents/header";
import Banner from "../../compontents/banner";
import styles from './index.module.css';
import Ball from "./components/ball";

export default function Home() {
    return (
        <div>
            <Header/>
            <Banner/>

            <div className={styles.ballContainer}>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
                <Ball/>
            </div>
        </div>
    )
}
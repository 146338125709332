import React from 'react';
import styles from './index.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";


export default function Header() {
    return (
        <div className={`${styles.container} px-3 py-4`}>
            <div>Logo</div>
            <FontAwesomeIcon icon={faBars} size={'lg'}/>
        </div>
    )
}